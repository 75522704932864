import {
    ApiChartAndResults,
    ApiError,
    assertUnreachable,
    Dashboard,
    DashboardFilters,
    DashboardTileTypes,
    InteractivityOptions,
} from '@lightdash/common';
import { Flex } from '@mantine/core';
import { IconUnlink } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import React, {
    ComponentProps,
    FC,
    useCallback,
    useEffect,
    useState,
} from 'react';
import { Layout, Responsive, WidthProvider } from 'react-grid-layout';
import { useParams } from 'react-router-dom';
import { lightdashApi } from '../api';
import { FiltersProvider } from '../components/common/Filters/FiltersProvider';
import SuboptimalState from '../components/common/SuboptimalState/SuboptimalState';
import ActiveFilters from '../components/DashboardFilter/ActiveFilters';
import DashboardChartTile, {
    GenericDashboardChartTile,
} from '../components/DashboardTiles/DashboardChartTile';
import LoomTile from '../components/DashboardTiles/DashboardLoomTile';
import MarkdownTile from '../components/DashboardTiles/DashboardMarkdownTile';
import {
    DashboardProvider,
    useDashboardContext,
} from '../providers/DashboardProvider';
import '../styles/react-grid.css';
import {
    getReactGridLayoutConfig,
    getResponsiveGridLayoutProps,
} from './Dashboard';
const useEmbedDashboard = (
    projectUuid: string,
    embedToken: string | undefined,
) => {
    return useQuery<Dashboard & InteractivityOptions, ApiError>({
        queryKey: ['embed-dashboard'],
        queryFn: async () =>
            lightdashApi<Dashboard & InteractivityOptions>({
                url: `/embed/${projectUuid}/dashboard`,
                method: 'POST',
                headers: {
                    'Lightdash-Embed-Token': embedToken!,
                },
                body: undefined,
            }),
        enabled: !!embedToken,
        retry: false,
    });
};

const useEmbedChartAndResults = (
    projectUuid: string,
    embedToken: string | undefined,
    chartUuid: string | null,
    dashboardFilters?: DashboardFilters,
) => {
    return useQuery<ApiChartAndResults, ApiError>({
        queryKey: [
            'embed-chart-and-results',
            projectUuid,
            chartUuid,
            dashboardFilters,
        ],
        queryFn: async () =>
            lightdashApi<ApiChartAndResults>({
                url: `/embed/${projectUuid}/chart-and-results`,
                method: 'POST',
                headers: {
                    'Lightdash-Embed-Token': embedToken!,
                },
                body: JSON.stringify({
                    chartUuid,
                    dashboardFilters,
                }),
            }),
        enabled: !!embedToken,
        retry: false,
    });
};

const ResponsiveGridLayout = WidthProvider(Responsive);

const EmbedDashboardChartTile: FC<
    ComponentProps<typeof DashboardChartTile> & {
        projectUuid: string;
        embedToken: string;
        dashboardFilters?: DashboardFilters;
    }
> = ({ projectUuid, embedToken, dashboardFilters, ...rest }) => {
    const { isLoading, data, error } = useEmbedChartAndResults(
        projectUuid,
        embedToken,
        rest.tile.properties?.savedChartUuid ?? null,
        dashboardFilters,
    );

    return (
        <GenericDashboardChartTile
            {...rest}
            isLoading={isLoading}
            data={data}
            error={error}
        />
    );
};

const DashboardFilter: FC<{
    dashboardFilters: DashboardFilters;
    dashboardTiles: Dashboard['tiles'];
}> = ({ dashboardFilters, dashboardTiles }) => {
    const [openPopoverId, setPopoverId] = useState<string>();

    const setDashboardFilters = useDashboardContext(
        (c) => c.setDashboardFilters,
    );

    const setDashboardTiles = useDashboardContext((c) => c.setDashboardTiles);

    useEffect(() => {
        setDashboardFilters(dashboardFilters);
        setDashboardTiles(dashboardTiles);
    }, [
        dashboardFilters,
        setDashboardFilters,
        setDashboardTiles,
        dashboardTiles,
    ]);

    const handlePopoverOpen = useCallback((id: string) => {
        setPopoverId(id);
    }, []);

    const handlePopoverClose = useCallback(() => {
        setPopoverId(undefined);
    }, []);
    const { projectUuid } = useParams<{ projectUuid: string }>();

    // FIXME fieldsWithSuggestions is required
    return (
        <FiltersProvider
            projectUuid={projectUuid}
            fieldsMap={{}}
            startOfWeek={undefined}
        >
            <Flex gap="xs" wrap="wrap" m="sm">
                <ActiveFilters
                    isEditMode={false}
                    onPopoverOpen={handlePopoverOpen}
                    onPopoverClose={handlePopoverClose}
                    openPopoverId={openPopoverId}
                />
            </Flex>
        </FiltersProvider>
    );
};

const EmbedDashboard: FC<{ embedToken: string }> = ({ embedToken }) => {
    const { projectUuid } = useParams<{ projectUuid: string }>();

    const dashboardFilters = useDashboardContext((c) => c.dashboardFilters);
    const haveFiltersChanged = useDashboardContext((c) => c.haveFiltersChanged);

    const { data: dashboard, error: dashboardError } = useEmbedDashboard(
        projectUuid,
        embedToken,
    );

    if (!embedToken) {
        return (
            <div style={{ marginTop: '20px' }}>
                <SuboptimalState
                    icon={IconUnlink}
                    title="This embed link is not valid"
                />
            </div>
        );
    }
    if (!projectUuid) {
        return (
            <div style={{ marginTop: '20px' }}>
                <SuboptimalState title="Missing project UUID" />
            </div>
        );
    }
    if (dashboardError) {
        return (
            <div style={{ marginTop: '20px' }}>
                <SuboptimalState
                    title="Error loading dashboard"
                    icon={IconUnlink}
                    description={
                        dashboardError.error.message.includes('jwt expired')
                            ? 'This embed link has expired'
                            : dashboardError.error.message
                    }
                />
            </div>
        );
    }

    if (!dashboard) {
        return (
            <div style={{ marginTop: '20px' }}>
                <SuboptimalState title="Loading..." loading />
            </div>
        );
    }

    if (dashboard.tiles.length === 0) {
        return (
            <div style={{ marginTop: '20px' }}>
                <SuboptimalState
                    title="Empty dashboard"
                    description="This dashboard has no tiles"
                />
            </div>
        );
    }

    const layouts = {
        lg: dashboard.tiles.map<Layout>((tile) =>
            getReactGridLayoutConfig(tile),
        ),
    };

    return (
        <>
            {dashboard.dashboardFiltersInteractivity?.enabled && (
                <DashboardFilter
                    dashboardFilters={dashboard.filters}
                    dashboardTiles={dashboard.tiles}
                />
            )}
            <ResponsiveGridLayout
                {...getResponsiveGridLayoutProps(false)}
                layouts={layouts}
            >
                {dashboard.tiles.map((tile) => (
                    <div key={tile.uuid}>
                        {tile.type === DashboardTileTypes.SAVED_CHART ? (
                            <EmbedDashboardChartTile
                                dashboardFilters={
                                    haveFiltersChanged
                                        ? dashboardFilters
                                        : undefined
                                }
                                projectUuid={projectUuid}
                                embedToken={embedToken}
                                key={tile.uuid}
                                minimal
                                tile={tile}
                                isEditMode={false}
                                onDelete={() => {}}
                                onEdit={() => {}}
                            />
                        ) : tile.type === DashboardTileTypes.MARKDOWN ? (
                            <MarkdownTile
                                key={tile.uuid}
                                tile={tile}
                                isEditMode={false}
                                onDelete={() => {}}
                                onEdit={() => {}}
                            />
                        ) : tile.type === DashboardTileTypes.LOOM ? (
                            <LoomTile
                                key={tile.uuid}
                                tile={tile}
                                isEditMode={false}
                                onDelete={() => {}}
                                onEdit={() => {}}
                            />
                        ) : (
                            assertUnreachable(
                                tile,
                                `Dashboard tile type is not recognised`,
                            )
                        )}
                    </div>
                ))}
            </ResponsiveGridLayout>
        </>
    );
};

const EmbedDashboardPage: FC = () => {
    const [embedToken] = useState(window.location.hash.replace('#', ''));
    const { projectUuid } = useParams<{ projectUuid: string }>();

    return (
        <DashboardProvider embedToken={embedToken} projectUuid={projectUuid}>
            <EmbedDashboard embedToken={embedToken} />
        </DashboardProvider>
    );
};
export default EmbedDashboardPage;
