import {
    ApiError,
    CreateEmbedJwt,
    DashboardBasicDetails,
    EmbedUrl,
} from '@lightdash/common';
import {
    ActionIcon,
    Button,
    Flex,
    Group,
    Input,
    Select,
    Stack,
    Switch,
    Text,
    TextInput,
    Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useClipboard } from '@mantine/hooks';
import { uuid4 } from '@sentry/utils';
import { IconEye, IconLink, IconPlus, IconTrash } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import React, { FC, useCallback, useState } from 'react';
import { lightdashApi } from '../../api';
import useToaster from '../../hooks/toaster/useToaster';
import MantineIcon from '../common/MantineIcon';
import EmbedCodeSnippet from './EmbedCodeSnippet';
import EmbedDashboardPreviewModal from './EmbedDashboardPreviewModal';

const useEmbedUrlCreateMutation = (projectUuid: string) => {
    const { showToastError } = useToaster();
    return useMutation<EmbedUrl, ApiError, CreateEmbedJwt>(
        (data: CreateEmbedJwt) =>
            lightdashApi<EmbedUrl>({
                url: `/embed/${projectUuid}/get-embed-url`,
                method: 'POST',
                body: JSON.stringify(data),
            }),
        {
            mutationKey: ['create-embed-url'],
            onError: (error) => {
                showToastError({
                    title: `We couldn't create your embed url.`,
                    subtitle: error.error.message,
                });
            },
        },
    );
};

type FormValues = {
    dashboardUuid: string | undefined;
    expiresIn: string;
    userAttributes: Array<{
        uuid: string;
        key: string;
        value: string;
    }>;
    dashboardFiltersInteractivity: {
        enabled: boolean;
    };
    externalId?: string;
};
const EmbedUrlForm: FC<{
    projectUuid: string;
    siteUrl: string;
    dashboards: DashboardBasicDetails[];
}> = ({ projectUuid, siteUrl, dashboards }) => {
    const { showToastSuccess } = useToaster();
    const clipboard = useClipboard({ timeout: 2000 });
    const { mutateAsync: createEmbedUrl } =
        useEmbedUrlCreateMutation(projectUuid);
    const [previewUrl, setPreviewUrl] = useState<string>();
    const form = useForm<FormValues>({
        initialValues: {
            dashboardUuid: undefined,
            expiresIn: '1 hour',
            userAttributes: [{ uuid: uuid4(), key: '', value: '' }] as Array<{
                uuid: string;
                key: string;
                value: string;
            }>,
            dashboardFiltersInteractivity: {
                enabled: false,
            },
        },
        validate: {
            dashboardUuid: (value: undefined | string) => {
                return value && value.length > 0
                    ? null
                    : 'Dashboard is required';
            },
        },
    });
    const { onSubmit, validate, values: formValues } = form;

    const convertFormValuesToCreateEmbedJwt = useCallback(
        (values: FormValues): CreateEmbedJwt => {
            return {
                expiresIn: values.expiresIn,
                content: {
                    type: 'dashboard',
                    dashboardUuid: values.dashboardUuid!,
                    dashboardFiltersInteractivity: {
                        enabled: values.dashboardFiltersInteractivity?.enabled,
                    },
                },
                userAttributes: values.userAttributes.reduce(
                    (acc, item) => ({
                        ...acc,
                        [item.key]: item.value,
                    }),
                    {},
                ),
                user: {
                    externalId: values.externalId,
                },
            };
        },
        [],
    );

    const handlePreview = useCallback(() => {
        const state = validate();
        if (state.hasErrors) {
            return;
        }

        createEmbedUrl(convertFormValuesToCreateEmbedJwt(formValues)).then(
            (data) => {
                setPreviewUrl(data.url);
            },
        );
    }, [
        formValues,
        validate,
        convertFormValuesToCreateEmbedJwt,
        createEmbedUrl,
    ]);

    const handleGenerateUrl = onSubmit((values) => {
        createEmbedUrl(convertFormValuesToCreateEmbedJwt(values)).then(
            (data) => {
                clipboard.copy(data.url);
                showToastSuccess({ title: 'Copied to clipboard!' });
            },
        );
    });

    return (
        <form id="generate-embed-url" onSubmit={handleGenerateUrl}>
            <Stack mb={'md'}>
                <Stack spacing="xs">
                    <Title order={5}>Preview</Title>
                    <Text color="dimmed">
                        Preview your embed URL and copy it to your clipboard.
                    </Text>
                </Stack>
                <Select
                    required
                    label={'Dashboard'}
                    data={dashboards.map((dashboard) => ({
                        value: dashboard.uuid,
                        label: dashboard.name,
                    }))}
                    placeholder="Select a dashboard..."
                    searchable
                    withinPortal
                    {...form.getInputProps('dashboardUuid')}
                />
                <Select
                    required
                    label={'Expires in'}
                    data={['1 hour', '1 day', '1 week', '30 days', '1 year']}
                    withinPortal
                    {...form.getInputProps('expiresIn')}
                />
                <Input.Wrapper label="User identifier">
                    <TextInput
                        size={'xs'}
                        placeholder="1234"
                        {...form.getInputProps(`externalId`)}
                    />
                </Input.Wrapper>

                <Input.Wrapper label="User attributes">
                    {form.values.userAttributes.map((item, index) => (
                        <Group key={item.uuid} mt="xs">
                            <TextInput
                                size={'xs'}
                                placeholder="E.g. user_country"
                                {...form.getInputProps(
                                    `userAttributes.${index}.key`,
                                )}
                            />
                            <TextInput
                                size={'xs'}
                                placeholder="E.g. US"
                                {...form.getInputProps(
                                    `userAttributes.${index}.value`,
                                )}
                            />
                            <ActionIcon
                                variant="light"
                                onClick={() =>
                                    form.removeListItem('userAttributes', index)
                                }
                            >
                                <MantineIcon color="red" icon={IconTrash} />
                            </ActionIcon>
                        </Group>
                    ))}
                    <Group>
                        <Button
                            size="xs"
                            mr="xxs"
                            variant="default"
                            mt="xs"
                            leftIcon={<MantineIcon icon={IconPlus} />}
                            onClick={() =>
                                form.insertListItem('userAttributes', {
                                    key: '',
                                    value: '',
                                    uuid: uuid4(),
                                })
                            }
                        >
                            Add attribute
                        </Button>
                    </Group>
                </Input.Wrapper>
                <Input.Wrapper label="Interactivity">
                    <Stack mt="sm">
                        <Switch
                            label="Allow users to change dashboard filters"
                            checked={
                                form.getInputProps(
                                    'dashboardFiltersInteractivity',
                                ).value?.enabled
                            }
                            onChange={() => {
                                const enabled = form.getInputProps(
                                    'dashboardFiltersInteractivity',
                                ).value?.enabled;

                                form.setFieldValue(
                                    'dashboardFiltersInteractivity',
                                    {
                                        enabled: !enabled,
                                    },
                                );
                            }}
                        />
                    </Stack>
                </Input.Wrapper>

                <Flex justify="flex-end" gap="sm">
                    <Button
                        variant={'light'}
                        leftIcon={<MantineIcon icon={IconEye} />}
                        onClick={handlePreview}
                    >
                        Preview
                    </Button>
                    <Button
                        variant={'outline'}
                        type="submit"
                        leftIcon={<MantineIcon icon={IconLink} />}
                    >
                        Generate & copy URL
                    </Button>
                </Flex>
            </Stack>
            <Stack mb="md">
                <Stack spacing="xs">
                    <Title order={5}>Code snippet</Title>
                    <Text color="dimmed">
                        Copy and paste this code snippet into your application
                        to generate embed urls.
                    </Text>
                </Stack>
                <EmbedCodeSnippet
                    projectUuid={projectUuid}
                    siteUrl={siteUrl}
                    data={convertFormValuesToCreateEmbedJwt(formValues)}
                />
            </Stack>
            {previewUrl && (
                <EmbedDashboardPreviewModal
                    opened={true}
                    embedUrl={previewUrl}
                    onClose={() => setPreviewUrl(undefined)}
                    size="xl"
                    fullScreen
                />
            )}
        </form>
    );
};

export default EmbedUrlForm;
